<template>
  <Header></Header>
  <div class="main-height">
    <section class="activated">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12">
              <h2 class="fw-bold">
                Payment process
              </h2>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-12">
              <div class="text-3 mt-2  text-justify">
                Your payment is still in process. Try again later.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <Footer></Footer>
</template>

<script>
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "Payment process",
  components: {
    Header,
    Footer
  },
  data() {
    return {
    }
  },
  methods: {
  }
})
</script>

<style>
@import '../assets/style.css';
</style>